// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {JpWMdxOEs: {hover: true, pressed: true}};

const cycleOrder = ["JpWMdxOEs", "ACCLKNz9T"];

const variantClassNames = {ACCLKNz9T: "framer-v-158dzkv", JpWMdxOEs: "framer-v-1dim43o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Link – Pressed": "ACCLKNz9T", Link: "JpWMdxOEs"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; tap?: any; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "JpWMdxOEs", link: jyVokMV05, tap: Hx8b1cBWd, title: k1WSCmK2G = "Text", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JpWMdxOEs", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1seocxu = activeVariantCallback(async (...args) => {
if (Hx8b1cBWd) {
const res = await Hx8b1cBWd(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-km59k", classNames)} style={{display: "contents"}}>
<Link href={jyVokMV05} openInNewTab={false} {...addPropertyOverrides({ACCLKNz9T: {href: undefined}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-1dim43o", className)} framer-19rzzd0`} data-framer-name={"Link"} data-highlight layoutDependency={layoutDependency} layoutId={"JpWMdxOEs"} onTap={onTap1seocxu} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"JpWMdxOEs-hover": {"data-framer-name": undefined}, "JpWMdxOEs-pressed": {"data-framer-name": undefined}, ACCLKNz9T: {"data-framer-name": "Link – Pressed", "data-highlight": undefined, onTap: undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy01MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "0.5px", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-transform": "uppercase"}}>Text</motion.p></React.Fragment>} className={"framer-p6b9i1"} data-framer-name={"Reviews"} fonts={["GF;Poppins-500"]} layoutDependency={layoutDependency} layoutId={"fwp6t7FCF"} style={{"--extracted-r6o4lv": "rgba(22, 22, 22, 0.5)", "--framer-paragraph-spacing": "0px"}} text={k1WSCmK2G} transition={transition} variants={{"JpWMdxOEs-hover": {"--extracted-r6o4lv": "rgb(22, 22, 22)"}, "JpWMdxOEs-pressed": {"--extracted-r6o4lv": "rgb(22, 22, 22)"}, ACCLKNz9T: {"--extracted-r6o4lv": "rgb(22, 22, 22)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-km59k [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-km59k .framer-19rzzd0 { display: block; }", ".framer-km59k .framer-1dim43o { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 28px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-km59k .framer-p6b9i1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-km59k .framer-v-1dim43o .framer-1dim43o { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-km59k .framer-1dim43o { gap: 0px; } .framer-km59k .framer-1dim43o > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-km59k .framer-1dim43o > :first-child { margin-left: 0px; } .framer-km59k .framer-1dim43o > :last-child { margin-right: 0px; } }", ".framer-km59k.framer-v-158dzkv .framer-1dim43o { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 36.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"ACCLKNz9T":{"layout":["auto","fixed"]},"bQLIoRDVr":{"layout":["auto","fixed"]},"pTzmCUdVb":{"layout":["auto","fixed"]}}}
 * @framerVariables {"jyVokMV05":"link","Hx8b1cBWd":"tap","k1WSCmK2G":"title"}
 */
const Framero2EwNLdG6: React.ComponentType<Props> = withCSS(Component, css, "framer-km59k") as typeof Component;
export default Framero2EwNLdG6;

Framero2EwNLdG6.displayName = "Header – Link Copy";

Framero2EwNLdG6.defaultProps = {height: 28, width: 36.5};

addPropertyControls(Framero2EwNLdG6, {variant: {options: ["JpWMdxOEs", "ACCLKNz9T"], optionTitles: ["Link", "Link – Pressed"], title: "Variant", type: ControlType.Enum}, jyVokMV05: {title: "Link", type: ControlType.Link}, Hx8b1cBWd: {title: "Tap", type: ControlType.EventHandler}, k1WSCmK2G: {defaultValue: "Text", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framero2EwNLdG6, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/o2EwNLdG6:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf", weight: "500"}])